/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-04-03T11:36:09.163Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.2.10000-1777152';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
